* {
  box-sizing: border-box;
  font-family: "Comic Sans MS", "Helvetica", sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: black;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.appCustom__btn {
  color: #61dafb;
  background: black;
  padding: 0.5rem 1rem;
  border-radius: 25px;
  font-size: 1.2rem;
  font-weight: bold;

  &:hover {
    color: white;
    background: #12667d;
  }
}
